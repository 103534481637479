import React, { useRef } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/Seo/Seo"
import Container from "../components/Container/Container"
import G from "../images/groenhout_g.png"
import SmallHero from "../components/SmallHero/SmallHero"
import ImageGallery from "../components/WoningComponent/ImageGallery/ImageGallery"
import WoningInfo from "../components/WoningComponent/WoningInfo/WoningInfo"
import WoningText from "../components/WoningComponent/WoningText/WoningText"
import WoningContact from "../components/WoningComponent/WoningContact/WoningContact"
import WoningInfoAccordion from "../components/WoningComponent/WoningInfoAccordion/WoningInfoAccordion"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft, faArrowsAltH, faMapMarkerAlt, faChevronRight } from "@fortawesome/free-solid-svg-icons"
import WoningBijlagen from "../components/WoningComponent/WoningBijlagen/WoningBijlagen"
import SmallHeroNew from "../components/SmallHeroNew/SmallHeroNew"

const SingleWoningTemplate = ({ data }) => {

  const myRef = useRef(null)

  // Basic house info and small calculations / formatting
  const title = `${data.wpWoning.hqAdresStraat} ${data.wpWoning.hqAdresNr}${data.wpWoning.hqAdresNrToevoeging}, ${data.wpWoning.hqAdresPostcode.toUpperCase()} ${data.wpWoning.hqAdresPlaats}`
 
  const priceInt = data.wpWoning.hqKoopprijs !== '' ? parseInt(data.wpWoning.hqKoopprijs) : data.wpWoning.hqHuurprijs !== '' ? parseInt(data.wpWoning.hqHuurprijs) : 0
  const priceCurr = new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(priceInt).replace(',00', ',-');
  
  const woonoppervlak = <><FontAwesomeIcon icon={faArrowsAltH} className="mr-2" /> <span>{data.wpWoning.hqWoonoppervlak}m²</span></>
  const wijk = <><FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" /><span>{data.wpWoning.hqAdresPlaats}</span></>

  let conditie = '';
  
  if(data.wpWoning.hqKoopconditie !== ''){
      data.wpWoning.hqKoopconditie === 'Kosten_koper' ? 
      conditie = 'k.k.'
      :
      conditie = 'v.o.n.'
  }else if(data.wpWoning.hqHuurconditie !== ''){
      data.wpWoning.hqHuurconditie === 'Per_maand' ? 
      conditie = '/ maand'
      :
      conditie = ''
  }

  const price = `${priceCurr} ${conditie}`

  const executeScroll = () => myRef.current.scrollIntoView()    

  if(!data) return null

  const seo = data.wpWoning.seo
  const seller = data.allWpVerkopendeMakelaar
  const breadcrumb = seo.breadcrumbs.map((e, i) => (
    <> 
    <span key={i}>
      <Link to={e.url === '/woningen/' ? '/aanbod/' : e.url} className="mr-2 text-gray"><span>{e.text === `Woningen` ? `Aanbod` : e.text === `Home` ? e.text : title}</span></Link>
      {(i < seo.breadcrumbs.length - 1) ? 
      <FontAwesomeIcon icon={faChevronRight} className="mr-2 text-gray" />
        : null
      }
    </span>
    </>
  ))
  
  const volgnummer = data.allWp.edges[0].node.acfOptionsAlgemeneInstellingen.volgnummer.volgnummerarr.split('}')
  const volgArray = volgnummer.map((e) => {
    let i = e.split(/[:;]/);
    // let f = i[1]
    let v = i.slice(-1).pop()
    let f = i.slice(1, 2).pop()
    let a = f !== undefined ? f.substring(0, f.indexOf('.jpg')).replace('"', '') : null
    // console.log(i)
    let img = {
      "volgnummer": v,
      "filename": a,
    }
    return(img)
    })

  // First Image of House
  // const mainImg =  data.wpWoning.featuredImage !== null ? data.wpWoning.featuredImage.node.localFile.childImageSharp : null
  const mainImg = data.wpWoning.featuredImage !== null ? data.wpWoning.featuredImage.node.mediaItemUrl : null
  
  // const bgImg =  data.wpWoning.featuredImage !== null ? data.wpWoning.featuredImage.node.localFile.publicURL : null
  const bgImg = data.wpWoning.featuredImage !== null ? data.wpWoning.featuredImage.node.mediaItemUrl : null
  const images =  data.wpWoning.woningen.woningenImages !== null ? data.wpWoning.woningen.woningenImages.filter((e) => {
    if(e.title.includes('Plattegrond') || e.title.includes('Hoofdfoto') || e.title.includes('Document')){
      // console.log(e.title)
      return false;
    }
     else{
      return true;
     }
    }) : null
  const floorPlans =  data.wpWoning.woningen.woningenImages !== null ? data.wpWoning.woningen.woningenImages.filter((e) => {
    if(e.title.includes('Plattegrond')) {
      return true;
    }
     else{
      return false;
     }
    }) : null
  const documents = data.wpWoning.woningen.woningenImages !== null ? data.wpWoning.woningen.woningenImages.filter((e) => {
    if(e.title.includes('Document')){
      return true;
    }
     else{
      return false;
     }
    }) : null


  // Header data
    const subtitle = [
      wijk,
      price,
      woonoppervlak
    ]

    const customMetaDesc = `${data.wpWoning.hqAdresStraat} ${data.wpWoning.hqAdresNr}${data.wpWoning.hqAdresNrToevoeging} | ${data.wpWoning.hqObjecttype.replaceAll('_', ' ')} in ${data.wpWoning.hqAdresPlaats}${data.wpWoning.hqStatus.replaceAll('_', ' ') !== 'Verkocht' ? ' te koop' : ''} |${data.wpWoning.hqWoonoppervlak ? ` ${data.wpWoning.hqWoonoppervlak} m² woonoppervlak |` : null}  ${data.wpWoning.hqKamers} kamers (${data.wpWoning.hqSlaapkamers} slaapkamers) | Energielabel ${data.wpWoning.hqEnergy} | Bekijk het nu!`

    console.log(data.wpWoning.hqEnergy)

  return(
    <>
      <Seo title={seo.title} description={customMetaDesc} status={data.wpWoning.hqStatus.replaceAll('_', ' ') === 'Verkocht' ? 'VERKOCHT' : null}></Seo>
      <SmallHeroNew title={data.wpWoning.hqAdres} subtitle={subtitle} overlay={true} overlayImage={G} image={bgImg !== undefined ? bgImg : ''}></SmallHeroNew>

      <Container styles={'my-16 lg:px-0 px-8'}>
        <div className="flex lg:flex-row flex-col lg:items-center md:items-left mb-12 w-full lg:mx-0">
          <div className="lg:w-1/12 sm:w-2/12 w-4/12">
            <Link to={data.wpWoning.hqStatus.replaceAll('_', ' ') === 'Verkocht' ? `/aanbod/verkocht` : `/aanbod`}><span className="text-blue block p-3 pt-2 pb-1 lg:mb-0 md:mb-4 flex flex-row justify-left items-center"><FontAwesomeIcon icon={faArrowLeft} /><span className="w-full ml-5">Terug</span></span></Link>
          </div>
          <div className="lg:ml-10 lg:mt-0 mt-4 mb-0 flex flex-row items-center">{breadcrumb}</div>
        </div>
       
        <ImageGallery images={ images !== null ? images : null} volgnummers={volgArray} status={data.wpWoning.hqStatus.replaceAll('_', ' ')} openHuis={data.wpWoning.hqOpenHuis} openStart={data.wpWoning.hqOpenHuisStart} openEind={data.wpWoning.hqOpenHuisEind} title={title} main={mainImg} />
        <Container>
          <div className="flex lg:flex-row flex-col">
            <div className="xl:w-9/12 lg:w-8/12 w-full lg:mx-0">
              <h2 className="text-blue mb-0">{data.wpWoning.hqAdres}</h2> 
              <p className="text-gray mt-1 mb-4"><span className="uppercase">{data.wpWoning.hqAdresPostcode} </span><span>{data.wpWoning.hqAdresPlaats}</span></p>
              {/* <div className="my-4">{data.wpWoning.hqWoonoppervlak}m² wonen  &bull; {data.wpWoning.hqPerceel}m² perceel  &bull;  {data.wpWoning.hqSlaapkamers} slaapkamers  </div> */}
              <h3 className="text-blue">{price}</h3>
              <WoningInfo data={data.wpWoning} price={price} surface={woonoppervlak} />
              <WoningText data={data} />
              <WoningInfoAccordion data={data} />
            </div>
            <div className="xl:w-3/12 lg:w-4/12 lg:ml-8 md:w-8/12 w-full lg:mx-0">
              <WoningContact scrollHandler={executeScroll} seller={seller} image={mainImg !== undefined ? mainImg : ''} contactpersoon={data.wpWoning.hqContactpersoon} vestiging={data.wpWoning.hqVestiging} origin={data.wpWoning.hqStatus.replaceAll('_', ' ') === 'Verkocht' ? 1 : null} />
            </div>
          </div>
        </Container>
        
      </Container>
      <WoningBijlagen data={data.wpWoning} floorplan={floorPlans} documents={documents} scrollRef={myRef}></WoningBijlagen>
    </>
  )
}

export default SingleWoningTemplate;

export const pageQuery = graphql`
  query($id: Int!){
    allWp {
      edges {
        node {
          acfOptionsAlgemeneInstellingen {
            volgnummer {
              volgnummerarr
            }
          }
        }
      }
    }
    wpWoning(databaseId: { eq: $id }){
      woningen {
        woningenImages {
          parentDatabaseId
          mediaItemUrl
          title
          databaseId
          mediaDetails {
            file
          }
        }
      } 
      title
      content
      hqAdres
      hqBouwvorm
      hqKoopconditie
      hqKoopprijs
      hqHuurconditie
      hqHuurprijs
      hqStatus
      hqWoonoppervlak
      hqAdresNr
      hqAdresNrToevoeging
      hqAdresPlaats
      hqAdresPostcode
      hqAdresStraat
      hqKamers
      hqSlaapkamers
      hqObject
      hqObjecttype
      hqVestiging
      hqContactpersoon
      hqObjecttype
      hqInhoud
      hqBouwjaar
      hqDakType
      hqEnergy
      hqOppBergruimteExtern
      hqGebouwGebondenBuitenRuimte
      hqPerceel
      hqBadkamers
      hqVoorzieningen
      hqAanvaarding
      hqWarmWater
      hqIsolatie
      hqLigging
      hqTuinen
      hqHoofdtuin
      hqHoofdtuinBereikbaarAchterom
      hqHoofdtuinBreedte
      hqHoofdtuinLengte
      hqHoofdtuinOpp
      hqHoofdtuinPositie
      hqParkeren
      hqBerging
      hqGarage
      hqOpenHuis
      hqOpenHuisStart
      hqOpenHuisEind
      uri
      featuredImage {
        node {
          mediaItemUrl
        }
      }
      seo {
        metaDesc
        title
        breadcrumbs {
          text
          url
        }
      }
    }
    allWpVerkopendeMakelaar {
      nodes {
        title
        verkopendeMakelaars {
          fieldGroupName
          foto {
            mediaItemUrl
          }
          emailadres
          telefoonnummer
          vestiging
          volgnummer
        }
      }
    }
  }
`