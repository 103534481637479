import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "gatsby";
import GoogleMapReact from "google-map-react";
import React, { useEffect, useRef, useState } from "react";
import Container from "../../Container/Container";
import { useForm, ValidationError } from '@formspree/react';
import GravityForm from "../../GravityForm";

const WoningBijlagen = (props) => {
    const [state, handleSubmit] = useForm('xoqrdzap');
    const key =  process.env.GATSBY_GOOGLE_API_KEY
    const address = `${props.data.hqAdres.replace(/\s/g, '+')}+${props.data.hqAdresPlaats.replace(/\s/g, '+')}+${props.data.hqAdresNr}+${props.data.hqAdresNrToevoeging}`
    const docs = props.documents
    const plans = props.floorplan?.filter((e) => {
            if(e.mediaItemUrl.includes('.pdf')){ 
                return true
               }
                else{return false} 
     })
    const [latLoc, setLat] = useState('')
    const [longLoc, setLong] = useState('')
    let requestNameField;
    let requestEmailField;
    const [requestName, setRequestName] = useState('')
    const [requestEmail, setRequestEmail] = useState('')

    const doc = useRef(null)
    const map = useRef(null)

    const updateHandler = (e) => {
        if(e.target.type === 'tel'){
            requestEmailField = e.target.value
        } else{
            requestNameField = e.target.value
        }
    }

    useEffect(() => {
        fetch((`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`))
            .then(response => response.json())
            .then(resultData => {
                console.log(resultData)
                setLat(resultData.results.length > 0 ? resultData.results[0].geometry.location.lat : null)
                setLong(resultData.results.length > 0 ? resultData.results[0].geometry.location.lng : null)
            })
    }, [])

    // console.log(latLoc, longLoc, process.env.GOOGLE_API_KEY)

    const ref = props.scrollRef

    let docLink;
    let planLink;
    
    if(docs?.length > 0){
        docLink = docs[0].mediaItemUrl
    }else{docLink = ''}

    if(plans?.length > 0){
        planLink = plans[0].mediaItemUrl
    }else{planLink = ''}

    const submitHandler = (e) => {
        if(docLink !== ''){
            doc.current.click();   
        }
        if(planLink !== ''){
            map.current.click();   
        }
    }

    console.log(latLoc, longLoc)

    return(
        <div className="w-full bg-light lg:mt-64 mt-16 lg:py-24 py-16 lg:px-16 px-8" id="bijlagen" ref={ref}>
            <Container>
                <div className="flex lg:flex-row flex-col justify-between">
                <div className="xl:w-5/12 lg:w-6/12 w-full lg:mx-0 mx-auto lg:pr-8 relative map__style">
                    <div className="w-full absolute lg:-top-56 map__style">
                        <GoogleMapReact bootstrapURLKeys={{ key: key }} center={{lat: latLoc, lng: longLoc}} defaultZoom={16}>
                            <FontAwesomeIcon icon={faMapMarkerAlt} lat={latLoc} lng={longLoc} size="3x" className="text-red" />
                        </GoogleMapReact>
                    </div>
                </div>
                <div className="xl:w-6/12 lg:w-5/12 w-fulllg:mx-0 mx-auto lg:pl-8 lg:mt-0 mt-12">
                    <h4 className="text-blue text-2xl font-bold">Downloads</h4>
                    <p className="text-gray text-md">Download hier alle beschikbare bestanden voor {props.data.title}</p>
                        <span className={`${state.succeeded && docs?.length !== 0  ? `block` : `hidden`} underline text-blue cursor-pointer`}><a target="_blank" ref={doc} href={docLink}>Brochures</a></span> 
                        <span className={`${state.succeeded && plans?.length !== 0  ? `block` : `hidden`} underline block text-blue cursor-pointer`}><a target="_blank" ref={map} href={planLink}>Plattegronden</a></span> 
                    <GravityForm id="5" submitFunc={submitHandler} objectTitel={props.data.title}></GravityForm>
                </div>
                </div>
            </Container>
        </div>
    )
}

export default WoningBijlagen;