import React from "react";
import './woningInfo.css';

const WoningInfoAccordion = (props) => {

    const data = props.data
    const koopConditie = data.wpWoning.hqKoopconditie === 'Kosten_koper' ? 'k.k.' : 'v.o.n.'
    const price = `${new Intl.NumberFormat('nl-NL', { style: 'currency', currency: 'EUR' }).format(parseInt(data.wpWoning.hqKoopprijs))} ${koopConditie}`
    const status = data.wpWoning.hqStatus.replaceAll('_', ' ')
    const bouwvorm = data.wpWoning.hqBouwvorm.replaceAll('_', ' ')
    const object = data.wpWoning.hqObject
    const objecttype = data.wpWoning.hqObjecttype.replaceAll('_', ' ')
    const bouwjaar = data.wpWoning.hqBouwjaar
    const dakType = data.wpWoning.hqDakType.replaceAll('_', ' ')
    const woonoppervlak = <span>{data.wpWoning.hqWoonoppervlak} m²</span>
    const perceeloppervlak = <span>{data.wpWoning.hqPerceel} m²</span>
    const inhoud = <span>{data.wpWoning.hqInhoud} m³</span>
    const kamers = data.wpWoning.hqKamers
    const energy = data.wpWoning.hqEnergy
    const voorzieningen = data.wpWoning.hqVoorzieningen.replaceAll('_', ' ')
    const water = data.wpWoning.hqWarmWater.replaceAll('_', ' ')
    const aanvaarding = data.wpWoning.hqAanvaarding.replaceAll('_', ' ')
    const bergruimteExtern = <span>{data.wpWoning.hqOppBergruimteExtern} m²</span>
    const gebondenBuitenruimte = <span>{data.wpWoning.hqGebouwGebondenBuitenRuimte} m²</span>
    const isolatie = data.wpWoning.hqIsolatie !== undefined && data.wpWoning.hqIsolatie !== '' ? data.wpWoning.hqIsolatie.replaceAll('_', ' ') : ''
    const ligging = data.wpWoning.hqLigging !== '' ? data.wpWoning.hqLigging.replaceAll('_', ' ') : ''
    const tuinen = data.wpWoning.hqTuinen.replaceAll('_', ' ')
    const hoofdtuin = data.wpWoning.hqHoofdtuin
    const hoofdtuinBereikbaarAchterom = data.wpWoning.hqHoofdtuinBereikbaarAchterom
    const tuinLigging = data.wpWoning.hqHoofdtuinPositie.toLowerCase()
    const tuinOpp = <span>{data.wpWoning.hqHoofdtuinOpp} m²</span>
    const tuinL = parseInt(data.wpWoning.hqHoofdtuinLengte) / 100
    const tuinB = parseInt(data.wpWoning.hqHoofdtuinBreedte) / 100
    const schuur = data.wpWoning.hqBerging !== null ? data.wpWoning.hqBerging.replaceAll('_', ' ') : ''
    const garage = data.wpWoning.hqGarage !== null ? data.wpWoning.hqGarage.replaceAll('_', ' ') : ''

    let parkeren = data.wpWoning.hqParkeren

    // console.log(data)

    if(parkeren !== null){
       parkeren = parkeren.replaceAll('_', ' ')
    }

    return(
        <div className="mt-8">
            <h2 className="text-blue">Kenmerken</h2>
            <div className="tabs">
                <div className="tab">
                    <input type="checkbox" id="chck1" />
                    <label className="tab-label text-blue text-lg" htmlFor="chck1">Algemeen</label>
                    <div className="tab-content text-blue">
                        <table>
                            {data.wpWoning.hqKoopprijs !== '' ?
                            <tr>
                                <td>Vraagprijs</td>
                                <td>{price}</td>
                            </tr>
                            : null}
                            {status !== '' ?
                            <tr>
                                <td>Status</td>
                                <td>{status}</td>
                            </tr>
                            : null }
                            {aanvaarding !== '' ?
                            <tr>
                                <td>Aanvaarding</td>
                                <td>{aanvaarding}</td>
                            </tr>
                            : null}
                        </table>
                    </div>
                </div>
                <div className="tab">
                    <input type="checkbox" id="chck2" />
                    <label className="tab-label text-blue text-lg" htmlFor="chck2">Bouw</label>
                    <div className="tab-content text-blue">
                        <table>
                            {object !== '' ?
                            <tr>
                                <td>Soort woning</td>
                                <td>{object === 'Woonhuis' ? objecttype : object}</td>
                            </tr>
                            : null}
                            {bouwvorm !== '' ?
                            <tr>
                                <td>Bouwvorm</td>
                                <td>{bouwvorm}</td>
                            </tr>
                            : null}
                            {bouwjaar !== '' ?
                            <tr>
                                <td>Bouwjaar</td>
                                <td>{bouwjaar}</td>
                            </tr>
                            : null}
                            {dakType !== '' ?
                            <tr>
                                <td>Soort dak</td>
                                <td>{dakType}</td>
                            </tr>
                            :null}
                        </table>
                    </div>
                </div>
                <div className="tab">
                    <input type="checkbox" id="chck3" />
                    <label className="tab-label text-blue text-lg" htmlFor="chck3">Oppervlakten en inhoud</label>
                    <div className="tab-content text-blue">
                        <table>
                            <tr>
                                <td>Woonoppervlakte</td>
                                <td>{woonoppervlak}</td>
                            </tr>
                            <tr>
                                <td>Gebouwgebonden buitenruimte</td>
                                <td>{gebondenBuitenruimte}</td>
                            </tr>
                            <tr>
                                <td>Externe bergruimte</td>
                                <td>{bergruimteExtern}</td>
                            </tr>
                            {data.wpWoning.hqPerceel !== "" ? 
                            <tr>
                                <td>Perceeloppervlakte</td>
                                <td>{perceeloppervlak}</td>
                            </tr>
                            : null}
                            <tr>
                                <td>Inhoud</td>
                                <td>{inhoud}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div className="tab">
                    <input type="checkbox" id="chck4" />
                    <label className="tab-label text-blue text-lg" htmlFor="chck4">Indeling</label>
                    <div className="tab-content text-blue">
                        <table>
                            <tr>
                                <td>Aantal kamers</td>
                                <td>{kamers}</td>
                            </tr>
                            {/* <tr>
                                <td>Aantal woonlagen</td>
                                <td>2</td>
                            </tr> */}
                            {voorzieningen !== '' ?
                            <tr>
                                <td>Voorzieningen</td>
                                <td>{voorzieningen}</td>
                            </tr>
                            :null}
                        </table>
                    </div>
                </div>{ energy !== '' || water !== '' ?
                <div className="tab">
                    <input type="checkbox" id="chck5" />
                    <label className="tab-label text-blue text-lg" htmlFor="chck5">Energie</label>
                    <div className="tab-content text-blue">
                        <table>
                            {energy !== '' ?
                            <tr>
                                <td>Energielabel</td>
                                <td>{energy}</td>
                            </tr>
                            :null}
                            {isolatie !== "" ?
                            <tr>
                                <td>Isolatie</td>
                                <td>{isolatie}</td>
                            </tr>
                            : null}
                            {water !== "" ?
                            <tr>
                                <td>Warm water</td>
                                <td>{water}</td>
                            </tr>
                            : null}
                        </table>
                    </div>
                </div>
                :null}
                <div className="tab">
                    <input type="checkbox" id="chck6" />
                    <label className="tab-label text-blue text-lg" htmlFor="chck6">Buitenruimte</label>
                    <div className="tab-content text-blue">
                        <table>
                            <tr>
                                <td>Ligging</td>
                                <td>{ligging}</td>
                            </tr>
                            <tr>
                                <td>Tuin</td>
                                <td>{tuinen}</td>
                            </tr>
                            {hoofdtuin !== '' ?
                            <tr>
                                <td>{hoofdtuin}</td>
                                <td>{tuinOpp} ({tuinL} meter diep en {tuinB} meter breed)</td>
                            </tr>
                            : null }
                            {tuinen !== 'Geen tuin' && tuinLigging !== '' ?
                            <tr>
                                <td>Ligging tuin</td>
                                <td>Gelegen op het {tuinLigging}en {hoofdtuinBereikbaarAchterom === '1' ? 'bereikbaar via achterom' : ''}</td>
                            </tr>
                            : null }
                            {/* <tr>
                                <td>Balkon/dakterras</td>
                                <td>Balkon aanwezig</td>
                            </tr> */}
                        </table>
                    </div>
                </div>
                { schuur !== '' ?
                <div className="tab">
                    <input type="checkbox" id="chck7" />
                    <label className="tab-label text-blue text-lg" htmlFor="chck7">Bergruimte</label>
                    <div className="tab-content text-blue">
                        <table>
                            <tr>
                                <td>Schuur/berging</td>
                                <td>{schuur}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                : null}
                {parkeren !== '' || garage !== 'Geen garage' ?
                <div className="tab">
                    <input type="checkbox" id="chck8" />
                    <label className="tab-label text-blue text-lg" htmlFor="chck8">Parkeergelegenheid</label>
                    <div className="tab-content text-blue">
                        <table>
                            {parkeren !== '' ? 
                            <tr>
                                <td>Soort parkeergelegenheid</td>
                                <td>{parkeren}</td>
                            </tr>
                            :null}
                            { garage !== 'Geen garage' ?
                            <tr>
                                <td>Soort garage</td>
                                <td>{garage}</td>
                            </tr>
                            : null}
                        </table>
                    </div>
                </div>
                :null}
            </div>
        </div>
    )
}

export default WoningInfoAccordion;