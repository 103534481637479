import React, { useState } from "react";

const WoningText = (props) => {
    
    const data = props.data
    console.log(data)
    const content = data.binnenkortVerkoop !== undefined ? data.content : data.aangekochteWoningen !== undefined ? data.content : data.wpWoning.content
    const [expanded, setExpanded] = useState(false)
    const clickHandler = () => {
        setExpanded(prevExpanded => !prevExpanded)
    }

    let exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
	var exp2 =/(^|[^\/])(www\.[\S]+(\b|$))/gim;
	

    let c;
    if(content !== null){
        const text = content.replace(exp, "<a target='_blank' style='color:#D61B27;' href='$1'>$1</a>");
        const text2 = text.replace(exp2, '$1<a target="_blank" href="http://$2">$2</a>');
        c = expanded ? text2 : text2.slice(0, text2.indexOf('</p>'))
    }else{
    c = ''
    }

    return(
        <>
        {content !== null ?
        <div className={`${expanded ? `max-h-64 transition-all` : `max-h-64 transition-all`} mt-6 p-4 bg-light main-content text-blue relative transition-all duration-300`}>
            <div className={`${expanded ? `max-h-44` : `max-h-44 `} transition-all duration-300 overflow-hidden`}>
               {<div dangerouslySetInnerHTML={{__html: c}} className={`${expanded ? `max-h-full` : `max-h-44 `} transition-all duration-300 overflow-hidden`}></div>}
            </div>
            <span className="text-red absolute bottom-4 left-4 cursor-pointer" onClick={clickHandler}>{`${expanded ? `Lees minder..` : `Lees meer...`}`}</span>
        </div>
        : ''    
    }
        </>
    )
}

export default WoningText;