import React from "react";

const WoningInfo = (props) => {

    const data = props.data
    const kamers = data.binnenkortVerkoop !== undefined ? data.binnenkortVerkoop.kamers : data.hqKamers
    const slaapkamers = data.binnenkortVerkoop !== undefined ? data.binnenkortVerkoop.slaapkamers : data.aangekochteWoningen !== undefined ? data.aangekochteWoningen.slaapkamers : data.hqSlaapkamers
    const objectType = data.binnenkortVerkoop !== undefined ? data.binnenkortVerkoop.objectType : data.aangekochteWoningen !== undefined ? data.aangekochteWoningen.objectType : data.hqObject
    const woonoppervlak = props.surface !== ' m²' ? props.surface : 'Niet beschikbaar'
    const woonopp = data.binnenkortVerkoop !== undefined ? data.binnenkortVerkoop.woonoppervlakte : null
    const price = props.price
    const p = data.binnenkortVerkoop !== undefined ? '' : null
    
    return(
        <div className="grid xl:grid-cols-3 grid-cols-2 gap-8 w-full lg:my-0 md:my-8">
                  {props.surface !== null || woonopp !== null ?
                  <div className="bg-blue text-light w-full p-4 md:p-8 flex flex-col align-center justify-center break-words">
                    <span className="font-bold">Woonoppervlak</span>
                    <p className="m-0 mt-1 text-sm text-light">{props.surface !== undefined ? woonoppervlak : woonopp}</p>
                  </div>
                  : null}

                  {kamers !== null ?
                  <div className="bg-light text-blue w-full p-4 md:p-8 flex flex-col align-center justify-center break-words">
                    <span className="font-bold">Aantal kamers</span>
                    <p className="m-0 mt-1 text-sm text-gray">{kamers} kamers <br />({slaapkamers} slaapkamers) </p>
                  </div>
                  : null}

                  {objectType !== null ?
                  <div className="bg-light text-blue w-full p-4 md:p-8 flex flex-col align-center justify-center break-words">
                    <span className="font-bold">Type woning</span>
                    <p className="m-0 mt-1 text-sm text-gray" style={{textTransform: "capitalize"}}>{objectType}</p>
                  </div>
                  : null}
        </div>
    )
}

export default WoningInfo;