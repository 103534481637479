import React, {  useState } from "react";
import g from '../../../images/groenhout_g.png'
import ImageModal from "./ImageModal/ImageModal";
import { motion } from "framer-motion";

const ImageGallery = (props) => {

    const imageData = props.images
    const title = props.title
    const main = props.main !== null ? props.main : null
    const status = props.status
    const openHuis = props.openHuis
    const dateStart = props.openStart ? new Date(props.openStart.replace(/-/g, '/')) : null
    const dateEnd = props.openEind ? new Date(props.openEind.replace(/-/g, '/')) : null

    const videoBtn = <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#fff" className="w-12 h-12">
    <path strokeLinecap="round" strokeLinejoin="round" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    <path strokeLinecap="round" strokeLinejoin="round" d="M15.91 11.672a.375.375 0 010 .656l-5.603 3.113a.375.375 0 01-.557-.328V8.887c0-.286.307-.466.557-.327l5.603 3.112z" />
  </svg>
  

    const dateString = dateStart !== null ? dateStart.toLocaleDateString('nl-NL', { weekday: 'long', day: 'numeric', month: 'long' }) : null
    const startString = dateStart !== null ? dateStart.toLocaleTimeString('nl-NL', {
      // en-US can be set to 'default' to use user's browser settings
      hour: '2-digit',
      minute: '2-digit',
    }) : null
    const endString = dateEnd !== null ? dateEnd.toLocaleTimeString('nl-NL', {
      // en-US can be set to 'default' to use user's browser settings
      hour: '2-digit',
      minute: '2-digit',
    }) : null

    const dateNow = new Date();

    const [modal, setModal] = useState(false)
    const volg = props.volgnummers
    let images = [];
    let imgs = [];
    let allImages = [];
    let thumbs = [];
    let imgOrder = [];

    const [slide, setSlide] = useState(0)

      if(imageData !== null && imageData !== undefined) {
      imageData.sort( (a, b) => {
        const index1 = volg.findIndex(e => {
          return a?.mediaDetails?.file?.includes(e.filename)
        })
        const index = volg.findIndex(e => {
          return b?.mediaDetails?.file?.includes(e.filename)
        })
        const s = index1 !== undefined ? parseInt(volg[index1]?.volgnummer.replaceAll('"', '')) : null
        const x = index !== undefined  ? parseInt(volg[index]?.volgnummer.replaceAll('"', '')) : null
        return(
          s !== null || x !== null ? s - x : null
          )
      })
      }else{}
  
    const modalHandler = () => {
      setSlide(5)
      setModal(prevModal => !prevModal)
    }

    const imgClickHandler = (e) => {
      if(e.target.attributes.imageId?.value === `hoofd`){
        setSlide(0)
        setModal(true) 
      }else{
        let number = parseInt(e.target.attributes.imageId?.value)
        setSlide(number + 1)
        setModal(true)
      }
    }

    let hidden = 'hidden';

    if(status === 'Beschikbaar' && openHuis !== 'True'){
      hidden = 'hidden'
    }else if(status !== 'Beschikbaar' || openHuis == 'True' && dateStart > dateNow ){
        hidden = 'block'
    }else if(status === undefined){
        hidden = 'hidden'
    }

    if(imageData !== null && imageData !== undefined) {
    images = imageData.map((e, i) => {
        let item;
          e.mediaItemUrl.endsWith('.jpg') ? 
            item = <motion.img initial={{translateY: -100, opacity: 0}} animate={{translateY: 0, opacity: 1}} transition={{duration: 0.3, delay: i * 0.15 + .15 }} src={e.mediaItemUrl} className="image__gallery__image max-h-full mb-0 object-cover h-auto w-full" ></motion.img>
          : item = <div className="relative h-full w-full"><motion.video initial={{translateY: -100, opacity: 0}} animate={{translateY: 0, opacity: 1}} transition={{duration: 0.3, delay: i * 0.15 + .15 }} className="image__gallery__image max-h-full mb-0 object-cover h-auto w-full" playsinline><source src={`${e.mediaItemUrl}#t=1,20`} type="video/mp4"></source></motion.video><div className="h-full w-full absolute top-0 bg-blue-medium flex items-center justify-center">{videoBtn}</div></div>
      return(
          item
    )})
    const mainImg = 
        <div className="h-full relative">  
          <img src={main} alt={``} imageId={`hoofd`} className=" h-full mb-0 object-contain mx-auto gallery__image" style={{
            objectFit:'contain',
            objectPosition: "50% 50%",
            height: '750px',
          }}/>
        </div>
    
     allImages = imageData?.map((e, i) => {

      let item;

      e.mediaItemUrl.endsWith('.jpg') ? 
        item = <img key={i} src={e.mediaItemUrl} alt={`${e.title}`} className="gallery__image max-h-full mb-0 object-cover h-auto w-full" style={{
          objectFit:'contain',
          objectPosition: "50% 50%",
          height: '750px',
        }}></img>
      : item = <div className="w-full flex justify-center lg:mt-24"><video className="gallery__image max-h-full mb-0 object-cover h-auto w-1/2" controls style={{
        objectFit:'contain',
        objectPosition: "50% 50%",
      }}><source src={e.mediaItemUrl} type="video/mp4"></source></video></div> 
      
      return (
        <div className="h-full relative">  
          {/* <img  className=" h-full mb-0 object-contain mx-auto gallery__image" /> */}
          {item}
        </div>
    )})

    allImages.unshift(mainImg)

    thumbs = imageData?.map((e, i) => (
      <div className="h-full bg-light">
        <img key={i} src={e.mediaItemUrl} alt={`${e.title}`} className=" h-full mb-0 mx-auto object-cover" style={{
          objectFit: "cover",
          objectPosition: "50% 50%",
          height: '150px'
        }}/>
      </div>
    ))
    }else{}

    if(imageData !== null && imageData !== undefined){
    imgs = [
    <div className={`image__gallery inline-flex flex-col h-1/2 lg:h-auto cursor-pointer ${images.length > 0 ? `lg:w-7/12` : `lg:w-full`} md:w-full p-0 pl-0 row-1 relative`} imageId={`hoofd`} onClick={imgClickHandler}> 
      <motion.img initial={{translateY: -100, opacity: 0}} animate={{translateY: 0, opacity: 1}} transition={{duration: 0.3, delay: 0 }} src={main} imageId={`hoofd`} className="h-full mb-0 main_gallery_image" style={{objectFit: 'cover'}}></motion.img>
      {/* <div className="absolute h-full w-full cursor-pointer" onClick={imgClickHandler} imageId={`hoofd`} style={{background: "linear-gradient(230deg, rgba(255,255,255, 0), rgba(255,255,255, 0.25), rgba(255,255,255,0.8))"}}></div> */}
      <span className={`bg-red opacity-100 text-white absolute pt-2 pb-2 px-5 top-0 left-0  ${hidden} `} >{status === 'Beschikbaar' ? null : status}{openHuis == 'True' && dateNow <= dateStart ? `Open huis: ${dateString} van ${startString} tot ${endString}` : null}</span>
      {/* <span className={`mt-10 absolute lg:bottom-5 md:bottom-10 bottom-5 left-5 flex flex-row items-center`}><span className="mr-3 text-white text-2xl bg-blue w-16 h-16 flex flex-row items-center justify-center"><FontAwesomeIcon icon={faMapMarkerAlt} /></span> <h3 className="text-blue m-0">{title}</h3></span> */}
    </div>,
    <div className={`image__gallery relative lg:grid-rows-2 lg:grid-cols-1 grid-rows-1 grid-cols-2 gap-y-3 gap-x-3 lg:gap-x-0 lg:w-3/12 md:10/12 p-0 lg:px-3 md:px-0 row-2 mt-3 lg:mt-0 ${images.length > 0 ? `grid` : `hidden`} `}>
      <div className="relative">
      {images[0]}
      <div className="absolute h-full w-full cursor-pointer top-0" onClick={imgClickHandler} imageId='0'></div>
      </div>
      <div className="relative">
      {images[1]}
      <div className="absolute h-full w-full cursor-pointer top-0" onClick={imgClickHandler} imageId='1'></div>
      </div>
      <div className="bg-blue-medium text-white text-xl flex items-center justify-center lg:max-h-1/3 max-h-full h-full absolute lg:top-2/3 md:top-0 lg:mt-2 lg:w-full w-6/12 lg:left-0 left-2/4 lg:ml-0 ml-1 bottom-0 cursor-pointer sm:hidden" onClick={modalHandler}>+ {images.length - 5}
        <div className="absolute h-full w-full" style={{backgroundImage: `url(${g})`,  backgroundSize: '60%', backgroundRepeat: 'no-repeat', backgroundPositionY: 'bottom'}}></div>
      </div>
    </div>,
    <div className={`image__gallery  lg:grid-rows-3 lg:grid-cols-1 md:grid-rows-1 md:grid-cols-3 gap-y-3 md:gap-x-3 lg:gap-x-0 lg:w-2/12 md:w-full lg:mt-0 md:mt-3 p-0 pr-0 row-3 relative ${images.length > 0 ? `sm:grid hidden` : `hidden`}`}>
      <div className="relative">
        {images[2]}
        <div className="absolute h-full w-full cursor-pointer top-0" onClick={imgClickHandler} imageId='2'></div>
      </div>
      <div className="relative">
        {images[3]}
        <div className="absolute h-full w-full cursor-pointer top-0" onClick={imgClickHandler} imageId='3'></div>
      </div>
      <div>
        {images[4]}
      </div>
      {images.length > 5 ? <div className="bg-blue-medium text-white text-xl flex items-center justify-center lg:max-h-1/3 md:max-h-full absolute lg:top-2/3 md:top-0 lg:mt-2 lg:w-full md:w-4/12 lg:left-0 md:left-2/3 lg:ml-0 md:ml-2 bottom-0 w-full cursor-pointer" onClick={modalHandler}>+ {images.length - 5}
        <div className="absolute h-full w-full" style={{backgroundImage: `url(${g})`,  backgroundSize: '60%', backgroundRepeat: 'no-repeat', backgroundPositionY: 'bottom'}}></div>
      </div> : null}
    </div>
    ]
  }else{
    imgs = [
    <div className="inline-flex flex-col w-full p-0 pl-0 row-1 relative" style={{maxHeight: '450px'}}> 
      <img src={main} className="h-full" style={{objectFit: 'cover'}}></img>
    </div>
    ]
  }

    return(
    <>
        <div className="image__gallery flex lg:flex-row flex-col w-full mx-0 flex-wrap h-auto mb-5" >
            {imgs}
        </div>
        <div className={`modal w-screen h-screen bg-white fixed flex left-0 top-0 justify-center items-center z-40 ${modal ? `block` : `hidden`}`}>
          <ImageModal images={allImages} slideIndex={slide} thumbs={thumbs} click={modalHandler} />
        </div>
    </>
    )
}

export default ImageGallery;